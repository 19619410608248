.property-list {
    /* Ensure your property-list has some width */
    width: 100%;
    max-width: 800px; /* Adjust based on your design */
    margin: auto; /* Center the container */
  }
  
  .search-container {
    display: flex;
    align-items: center; /* Center items vertically */
    border: 1px solid #ccc; /* Add a border */
    border-radius: 25px; /* Make it round */
    overflow: hidden; /* Ensure the button doesn't overflow */
    width: 100%; /* Match the width to the card */
  }
  
  .search-input {
    flex: 1; /* Allow the input to take available space */
    border: none; /* Remove default border */
    padding: 10px; /* Add some padding */
    border-radius: 25px 0 0 25px; /* Round left corners */
    outline: none; /* Remove outline on focus */
  }
  
  .search-button {
    background-color: #007bff; /* Button color */
    border: none; /* Remove default button border */
    padding: 10px 15px; /* Padding for the button */
    border-radius: 0 25px 25px 0; /* Round right corners */
    cursor: pointer; /* Pointer on hover */
    color: white; /* Button text color */
    display: flex; /* Center icon */
    align-items: center;
    justify-content: center;
  }
  
  .search-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }
  
.request-details {
    border: 1px solid #eaeaea; /* Grey outline */
    border-radius: 8px; /* Rounded corners */
    padding: 16px; /* Padding for the details */
   
    color: #000000; /* Black font color */
  }
  
  .request-title {
    font-size: 20px; /* Title font size */
    margin: 0 0 8px; /* Spacing below the title */
    font-weight: bold; /* Make title bold */
  }
  
  .divider {
    border: none; /* Remove default border */
    border-top: 1px solid #eaeaea; /* Horizontal line style */
    margin: 10px 0; /* Spacing around the line */
  }
  
  .request-content {
    font-size: 16px; /* Content font size */
    color: #000000; /* Ensure text is black */
  }
  
  .app-button {
    padding: 10px 20px;
    width: 100%;
    background-color: #2767e7;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    margin-bottom: 20px;
  }
  
  .app-button:hover {
    background-color: #eae1e4;
  }
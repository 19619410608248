.table-container {
    width: 100%;
    overflow-x: auto; /* Enable horizontal scrolling on small screens */
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  @media (max-width: 768px) {
    table {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
    }
  
    thead {
      display: none;
    }
  
    tr {
      display: block;
      margin-bottom: 10px;
    }
  
    td {
      display: block;
      text-align: right;
      position: relative;
      padding-left: 50%;
      box-sizing: border-box;
      border: none;
    }
  
    td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 10px;
      font-weight: bold;
      text-align: left;
    }
  }
  
/* Set common width for BagCard, BagRequestStatus, and chat-container */
.chat-bag-container {
    width: 100%; /* 100% of the parent container */
    max-width: 600px; /* You can adjust this max-width as necessary */
    margin: 0 auto; /* Center the container */
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adds space between elements */
}

.chat-container,
.bag-card,
.bag-request-status {
    width: 100%; /* Ensures all components take up the same width */
    border: 1px solid #ccc;
    border-radius: 8px; /* Rounded corners for a softer look */
    overflow: hidden; /* Prevents overflow from elements inside */
}

.button-container {
    display: flex;
    justify-content: space-between; /* Space between the buttons */
    margin-top: 20px; /* Space above the buttons */
    position: sticky; /* Make the container sticky */
    bottom: 0; /* Stick to the bottom of the container */
    background-color: white; /* Background color to cover underlying content */
    padding: 10px 0; /* Padding for some space around buttons */
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for separation */
    z-index: 10; /* Ensure it appears above other content */
}

.left-button, .right-button {
    padding: 10px 20px; /* Uniform padding for both buttons */
    font-size: 16px; /* Consistent font size */
    cursor: pointer;
    border: none; /* No border */
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.left-button {
    background-color: #0078d4; /* Primary color */
    color: white; /* Text color */
}

.left-button:hover {
    background-color: #005ea2; /* Darker shade on hover */
}

.right-button {
    background-color: #28a745; /* Secondary color */
    color: white; /* Text color */
}

.right-button:hover {
    background-color: #218838; /* Darker shade on hover */
}

.chat-messages {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    max-height: 300px; /* Adjust height as necessary */
}

.chat-message {
    padding: 8px;
    margin: 10px 0;
    display: flex;
    align-items: center;
}

.chat-message.received {
    background-color: #f3f3f3;
    border-left: 4px solid #0078d4; /* Highlight for received messages */
}

.chat-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover; /* Ensures avatar image fits inside circle */
}

.chat-message-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.chat-message-header {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: bold;
    color: #333;
}

.chat-message-text {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
}

.chat-input-container {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc; /* Added border for visual separation */
}

.chat-timestamp {
    font-size: 8px;  /* Smaller font size */
    color: #888;      /* Light grey color */
}

.chat-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-right: 10px;
}

.chat-send-button {
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
}

.chat-send-button:hover {
    background-color: #005ea2;
}

.left-button:disabled,
.right-button:disabled {
    background-color: #d3d3d3; /* Light grey background */
    color: #a9a9a9; /* Darker grey text */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
}

.section-heading {
    font-size: 1.2rem; /* Adjust as needed */
    color: #666; /* Light grey font color */
    margin: 16px 0 8px; /* Spacing above and below the heading */
    text-align: center; /* Center text inside these sections */

  }
  
  .section-divider {
    border: 0;
    border-top: 1px solid #ccc; /* Light grey line */
    margin: 0 0 16px; /* Spacing below the line */
    text-align: center; /* Center text inside these sections */

  }
  
/* PrivacyPolicy.css */

.privacy-policy-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .privacy-policy-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy-header h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .privacy-policy-header p {
    font-size: 1rem;
    color: #666;
  }
  
  .privacy-policy-content section {
    margin-bottom: 20px;
  }
  
  .privacy-policy-content h2 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 5px;
  }
  
  .privacy-policy-content p {
    font-size: 1rem;
    color: #333;
  }
  
  .privacy-policy-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .privacy-policy-content a:hover {
    text-decoration: underline;
  }
  
  .privacy-policy-footer {
    text-align: center;
    margin-top: 40px;
    font-size: 0.875rem;
    color: #666;
  }
  
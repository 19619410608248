.property-card-link {
    text-decoration: none;
  }
  
  .property-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #fff;
    padding: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .property-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .property-card-title {
    font-size: 20px;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }
  
  .property-card-price {
    font-size: 16px;
    color: #ff5a5f;
    margin-bottom: 4px;
  }
  
  .property-card-rating {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #777;
  }
  
  .prefer-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }
  
  .prefer-items img {
    margin-right: 10px;
    margin-bottom: 10px; /* Add space below each item */
  }
  
  .item-label {
    font-size: 14px;
    color: #555;
    margin-right: 10px;
  }
  
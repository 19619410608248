/* createbag.css */
.form-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold; /* Makes the label text bold */

  color: #333;
}

.form-control {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.form-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #0056b3;
}

/* createbag.css */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  margin: auto;
  position: relative;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.modal-button {
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.modal-button:hover {
  background-color: #0056b3;
}

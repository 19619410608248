/* messagebox.css */

/* General styling for the message box */
.message-box {
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
}

.message-text {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

/* Button styling */
.button-group {
  margin-bottom: 20px;
}

.button-group button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.button-group button:hover {
  background-color: #0056b3;
}

/* Styling for share icons */
.share-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping of icons to next line */
  gap: 10px; /* Space between icons */
}

.share-icon {
  font-size: 2rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
}

.share-icon:hover {
  color: #007bff; /* Change color on hover */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .message-text {
    font-size: 1rem;
  }

  .button-group button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }

  .share-icon {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .message-text {
    font-size: 0.9rem;
  }

  .button-group button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }

  .share-icon {
    font-size: 1.25rem;
  }
}

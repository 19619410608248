/* src/App.css */
/* index.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

footer {
  background-color: #4285c9;
  color: #fff;
}

.page-title {
  text-align: center; /* Center text horizontally */
  margin: 20px 0; /* Optional: Add some vertical margin */
}

/* Contact Host Button */
.app-button {
  padding: 10px 20px;
  background-color: #2767e7;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 20px;
}

.app-button:hover {
  background-color: #eae1e4;
}
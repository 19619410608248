/* Ensure the slide container takes the full width of the image */
.carousel-container {
    width: 100%;
    position: relative;
}

.slide {
    position: relative;
    width: 100%;
    overflow: hidden; /* Ensure no overflow if the content exceeds the container */
}

/* Ensure the image covers the full width of the container */
.slide-image {
    width: 100%;
    height: auto;
    display: block;
}

/* Style for the content overlay */
.slide-content {
    position: relative; /* Adjusted to relative for positioning below the image */
    width: 100%; /* Full width of the container */
    background: rgba(235, 233, 233, 0.9); /* Semi-transparent background for content */
    color: rgb(10, 10, 10);
    text-align: center;
    padding: 30px;
    box-sizing: border-box; /* Ensure padding is included in width */
    border-top: 1px solid #ddd; /* Optional: a thin border to separate content from image */
    border-radius: 5px 5px 0 0; /* Rounded corners at the top */
    margin-top: -1px; /* Remove default margin, adjust as needed */
}

/* Style for the slide title */
.slide-title {
    font-size: 24px;
    margin: 0 0 10px 0;
}

/* Style for the slide description */
.slide-description {
    font-size: 16px;
    margin: 0 0 20px 0;
}

/* Style for the slide buttons */
.slide-button {
    background-color: #2767e7;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 5px 2px;
    cursor: pointer;
    border-radius: 5px;
}

/* Style for the icon inside the button */
.search-icon {
    margin-right: 5px;
}

/* Style for the feature list container */
.feature-list-container {
    margin-top: 20px;
    padding: 0 10px; /* Padding for smaller screens */
}

/* Style for the feature list */
.feature-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

/* Style for each feature item */
.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

/* Style for the check icon */
.check-icon {
    margin-right: 10px;
    color: #4caf50; /* Green color for check icon */
}

/* Style for the feature text */
.feature-text {
    font-size: 16px;
    line-height: 1.5;
}

/* Responsive design adjustments */
@media (max-width: 768px) {
    .slide-title {
        font-size: 20px; /* Adjust font size for smaller screens */
    }

    .slide-description {
        font-size: 14px; /* Adjust font size for smaller screens */
    }

    .slide-button {
        font-size: 14px; /* Adjust button font size for smaller screens */
        padding: 8px 16px; /* Adjust button padding for smaller screens */
    }

    .feature-text {
        font-size: 14px; /* Adjust font size for feature list on smaller screens */
    }
}

@media (max-width: 480px) {
    .slide-title {
        font-size: 18px; /* Further adjust font size for very small screens */
    }

    .slide-description {
        font-size: 12px; /* Further adjust font size for very small screens */
    }

    .slide-button {
        font-size: 12px; /* Further adjust button font size for very small screens */
        padding: 6px 12px; /* Further adjust button padding for very small screens */
    }

    .feature-text {
        font-size: 12px; /* Further adjust font size for feature list on very small screens */
    }
}

/* src/ConfirmationMessageBox.css */
.confirmation-message-box {
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    text-align: center;
    font-family: 'Arial', sans-serif; /* Use a similar font */
  }
  
  .message {
    font-size: 18px; /* Larger font size for message */
    color: #333; /* Dark gray for readability */
    margin-bottom: 20px;
  }
  
  .button-group {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between buttons */
  }
  
  .front-button {
    background-color: #ff5a5f; /* Airbnb red */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .back-button {
    background-color: #f7f7f7; /* Light gray */
    color: #484848; /* Dark gray */
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .front-button:hover {
    background-color: #ff3b3f; /* Darker red on hover */
  }
  
  .back-button:hover {
    background-color: #eaeaea; /* Slightly darker gray on hover */
  }
  
/* Base styles */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f7f7f7;
}

/* Container styles */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

/* Form styles */
.login-form {
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  width: 400px;
  text-align: center;
}

/* Title styles */
.login-title {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Social buttons */
.social-buttons {
  margin-bottom: 20px;
}

.social-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s;
}

.google-button {
  background-color: #db4437;
  color: white;
}

.facebook-button {
  background-color: #4267B2;
  color: white;
}

.social-button:hover {
  opacity: 0.8;
}

/* Icon styles */
.social-icon {
  margin-right: 10px; /* Space between icon and text */
  font-size: 18px; /* Icon size */
}

/* Separator styles */
.separator {
  display: flex;
  align-items: center;
  margin: 20px 0; /* Space above and below the separator */
}

.separator hr {
  flex: 1; /* Take up remaining space */
  border: none;
  border-top: 1px solid #ccc; /* Line style */
  margin: 0 10px; /* Space around the line */
}

.separator span {
  font-weight: bold; /* OR text style */
}

/* Specificity increase for input styles */
.login-form input {
  width: 100%;
  padding: 12px;
  margin: 4px 0; /* Space between fields */
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

/* Add margin above the login button */
.login-button {
  width: 100%;
  padding: 12px;
  background-color: #2767e7;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px; /* Space between login button and input fields */
}

.login-button:hover {
  background-color: #2767e7;
}

/* Signup link styles */
.signup-link {
  margin-top: 15px;
}

.signup-link a {
  color: #2767e7;
  text-decoration: none;
}

.signup-link a:hover {
  text-decoration: underline;
}

.property-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
    padding: 16px;
  }
  
  .property-card-link {
    text-decoration: none; /* Remove underline from link */
    color: inherit; /* Inherit text color */
  }
  
  .property-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .property-card:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .property-card-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .property-card-content {
    padding: 16px;
  }
  
  .property-card-title {
    font-size: 1.2em;
    margin: 0;
  }
  
  .property-card-location {
    color: #555;
    margin: 8px 0;
  }
  
  .property-card-price {
    font-weight: bold;
    margin: 8px 0;
  }
  
  .property-card-rating {
    color: #f39c12;
  }
  
  
/* Base styles */
body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    background-color: #f7f7f7;
  }
  
  /* Container styles */
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 20px;
  }
  
  /* Form styles */
  .signup-form {
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 400px;
    text-align: center;
  }
  
  /* Title styles */
  .signup-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  /* Form input styles */
  .input-group {
    margin-bottom: 20px; /* Space between input groups */
    text-align: left; /* Align text to the left */
  }
  
  .signup-form input {
    width: 100%;
    padding: 12px;
    margin: 5px 0; /* Space between fields */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Error message styles */
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 5px; /* Space between input field and error message */
  }
  
  /* Button styles */
  .signup-button {
    width: 100%;
    padding: 12px;
    background-color: #ff385c;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .signup-button:hover {
    background-color: #e63950;
  }
  
  /* Login link styles */
  .login-link {
    margin-top: 15px;
  }
  
  .login-link a {
    color: #ff385c;
    text-decoration: none;
  }
  
  .login-link a:hover {
    text-decoration: underline;
  }
  
.account-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .account-options {
    list-style-type: none;
    padding: 0;
  }
  
  .account-options li {
    display: flex;
    align-items: center;
    margin: 10px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .account-options li:hover {
    background-color: #f0f0f0;
  }
  
  .icon {
    margin-right: 10px;
  }
  
/* pagebottom.css */
.pagebottom-custom {
    background-color: #f8f9fa; /* Match your main page background color */
    color: #333; /* Text color for readability */
    padding: 20px 0;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 10; /* Ensure it stays above other content */
    border-top: 1px solid #ddd; /* Optional border for separation */
  }
  
  .pagebottom-text {
    margin: 0;
    font-size: 0.875rem; /* Adjust font size for readability */
  }
  
  .pagebottom-link {
    color: #007bff; /* Link color */
    text-decoration: none;
  }
  
  .pagebottom-link:hover {
    text-decoration: underline;
  }
  
  .powered-by {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .armsoftware-link {
    display: flex;
    align-items: center;
    margin-left: 10px; /* Space between text and logo */
  }
  
  .armsoftware-logo {
    height: 30px; /* Adjust logo size to fit proportionally */
    margin-left: 8px;
    max-width: 100%; /* Ensure logo scales properly within its container */
  }
  
  .site-links {
    margin-top: 10px;
    text-align: center;
  }
  
  .site-links a {
    color: #007bff; /* Link color */
    text-decoration: none;
    margin: 0 10px; /* Space between links */
    font-size: 0.875rem; /* Adjust font size for readability */
  }
  
  .site-links a:hover {
    text-decoration: underline;
  }
  
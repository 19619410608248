/* src/Form.css */

.form-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    color: black;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .input {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    resize: none;
    font-size: 16px;
  }
  
  .button {
    width: 100%;
    padding: 10px;
    background-color: #2767e7;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #ff3b3b;
  }
  
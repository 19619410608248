/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* User Info Styles */
.user-info {
  display: flex;
  align-items: center; /* Vertically center items */
}

.user-info img {
  margin-right: 10px; /* Space between image and text */
}

.name-container {
  display: flex;
  align-items: center; /* Vertically center checkbox and name */
}

.checkbox {
  color: blue; /* Blue color for checkbox symbol */
  margin-right: 5px; /* Space between checkbox and name */
}

.firstname {
  margin-left: 5px; /* Optional: additional space on the left */
}

/* Button Styles */
button {
  cursor: pointer;
}

/* Contact Me Button Styles */
.name-container button {
  background-color: #007bff; /* Airbnb primary color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 20px; /* Rounded corners */
  padding: 5px 10px; /* Padding for button */
  font-size: 0.9rem; /* Smaller font size */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s ease; /* Smooth transition */
  margin-left: 10px; /* Space between name and button */
}

.name-container button:hover {
  background-color: #e04e53; /* Darker shade on hover */
}

/* Photo Component */
.photo-container {
  margin-bottom: 20px;
}

.photo-container img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Title Component */
.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 10px;
}

/* Date Component */
.date {
  color: #717171;
  font-size: 1rem;
  margin-bottom: 20px;
}

/* Key-Value Container */
.key-value-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 400px;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-left: 0; /* Align left with other content */
  margin-right: auto;
}

/* Key-Value Pair */
.key-value-pair {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #e0e0e0;
}

/* Key Styles */
.key {
  font-weight: bold;
  color: #333;
}

/* Value Styles */
.value {
  color: #555;
}

/* Status Icons */
.status-icon {
  display: inline-block;
  width: 24px; /* Increased size */
  height: 24px; /* Increased size */
  text-align: center;
  line-height: 24px; /* Match line-height to new height */
  font-size: 18px; /* Increased font size */
  font-weight: bold; /* Make it bold */
  border-radius: 50%;
}

.checked {
  color: green;
}

.unchecked {
  color: red;
}

/* Description Component */
.description {
  font-size: 1.125rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Owner Detail Card */
.owner-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
}

.owner-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.owner-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  object-fit: cover;
}

.owner-card h2 {
  margin-top: 0;
  font-size: 1.75rem;
}

.owner-card p {
  margin: 10px 0;
}

.owner-card ul {
  list-style-type: none;
  padding: 0;
}

.owner-card ul li {
  margin-bottom: 15px;
}

.owner-card ul li p {
  margin: 5px 0;
}

.property-card-rating {
  color: #f39c12;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .title {
    font-size: 2rem;
  }

  .description {
    font-size: 1rem;
  }
}

/* Sticky Button Styles */
.sticky-button {
  position: fixed; /* Fix the position */
  bottom: 20px; /* Space from the bottom */
  left: 20px; /* Space from the left */
  right: 20px; /* Space from the right */
  padding: 15px; /* Button padding */
  background-color: #007bff; /* Button color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1000; /* Ensures it stays above other elements */
  text-align: center; /* Center text */
}

.sticky-button:hover {
  background-color: #0056b3; /* Darker on hover */
}

/* Floating Button Styles */
.contact-button {
  position: fixed; /* Fix the position */
  bottom: 70px; /* Space from the bottom */
  right: 20px; /* Space from the right */
  padding: 15px 20px; /* Button padding */
  background-color: #007bff; /* Airbnb primary color */
  color: white; /* Text color */
  border: none; /* No border */
  border-radius: 25px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  cursor: pointer; /* Pointer cursor on hover */
  z-index: 1000; /* Ensure it stays above other elements */
  font-size: 1rem; /* Font size */
  transition: background-color 0.3s ease; /* Smooth transition */
}

.contact-button:hover {
  background-color: #007bff; /* Darker shade on hover */
}





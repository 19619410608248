/* footer.css */

.footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    border-top: 1px solid #e0e0e0;
    padding: 10px 0;
    position: fixed; /* Fix the footer at the bottom of the viewport */
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    height: 60px; /* Fixed height for the footer */
    z-index: 1000; /* Ensure the footer stays on top of other content */
}

.footer-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease;
    margin: 0 10px;
}

.footer-icon:hover {
    color: #ff5a5f; /* Airbnb primary color */
}

.icon {
    font-size: 1.5rem; /* Default icon size */
}

.footer-icon span {
    font-size: 0.8rem; /* Adjust font size */
    margin-top: 5px;
    color: #333333;
}

/* Responsive Design */
@media (max-width: 600px) {
    .footer {
        padding: 8px 0; /* Reduced padding on mobile */
        height: 50px; /* Adjust height on mobile */
    }

    .footer-icon {
        margin: 0 8px; /* Adjust margin for smaller screens */
    }

    .icon {
        font-size: 1.25rem; /* Slightly smaller icon size for mobile */
    }

    .footer-icon span {
        font-size: 0.7rem; /* Smaller font size for mobile */
    }
}
